import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { profileReducer } from './profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProfileStoreEffects } from './profile.effects';


@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([ProfileStoreEffects]),
        StoreModule.forFeature('profile', profileReducer),
    ]
})
export class ProfileStoreModule {
}
